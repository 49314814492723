@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
	body {
		@apply font-Roboto text-dark font-extralight text-[14px];
	}
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		@apply font-Orpheus font-normal;
	}
	.container {
		/* @apply xl:max-w-[1600px] 2xl:max-w-[1736px]; */
		/* @apply xl:max-w-[1536px]; */
	}
	.animation-bounce {
		animation: bounce 2s infinite;
	}
	.emerald-descriptions {
		@apply text-[15px] md:text-xl lg:text-2xl;
	}
	.emerald-descriptions .txt-1 {
		@apply mb-[36px] lg:mb-[60px];
	}
	.emerald-descriptions .txt-2 {
		@apply mb-[20px] lg:mb-[40px];
	}
	.emerald-descriptions .item > div:last-child .txt-1,
	.emerald-descriptions .item > div:last-child .txt-2 {
		@apply !mb-0;
	}
	.emerald-descriptions .title {
		@apply text-xl text-primary md:text-4xl mb-[37px] md:mb-[80px] text-center;
	}
	.emerald-descriptions .item {
		@apply mb-10 lg:mb-[136px] last:mb-0 lg:last:mb-0;
	}
	.footer-secure-icons {
		@apply font-light md:font-normal flex flex-wrap gap-8 justify-center text-[13px]  tracking-[0.05em];
	}
	.footer-secure-icons svg {
		@apply w-[20px] h-[20px];
	}
	.village-title {
		@apply text-2xl md:text-[40px] mb-[20px] md:mb-[30px] lg:mb-[40px] xl:mb-[70px] text-center font-normal;
	}
	.disc {
		@apply before:absolute before:w-[5px] before:h-[5px] before:bg-dark before:rounded-full relative before:-left-5 before:top-3 xl:before:top-4;
	}
	.btn {
		@apply border sm:border-2 uppercase text-xs sm:text-2xl lg:text-[28px] border-white text-white px-5 py-[2px] font-normal sm:min-h-12 inline-flex items-center hover:bg-black hover:text-white hover:border-black duration-300;
	}
}

@keyframes bounce {
	0%,
	20%,
	50%,
	80%,
	100% {
		transform: translate(-50%, 0);
	}
	40% {
		transform: translate(-50%, -30px);
	}
	60% {
		transform: translate(-50%, -15px);
	}
}
